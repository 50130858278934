<template>
  <div>
    <van-nav-bar
      :title="task_name"
      left-text=""
      left-arrow
      class="navbar"
      @click-left="goBack"
    />
    <div class="ninjia">
      <div class="ninjia-title">
        <div class="ctx-title">
          <div class="title-ctx">{{ task_name }}</div>
          <div class="title-fk"></div>
        </div>
        <img
          src="https://lesson.iapeap.com/images/EmotionalValue/sikao.png"
          alt=""
        />
      </div>
      <div class="jinritiyan">
        <div v-html="formattedText"></div>

        <div class="contentctx">
          {{ comment }}
        </div>

        <div class="imglist">
          <div v-for="(item, index) in img_url" :key="index">
            <img :src="item" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getEmoExercise, homePage } from "../../api/login";
export default {
  data() {
    return {
      days: "",
      stage: "",
      id: "",
      comment: "",
      task_info: "",
      img_url: [],
      task_name: "",
    };
  },
  computed: {
    formattedText() {
      return this.task_info.replace(/\n/g, "<br>");
    },
  },

  mounted() {
    this.id = this.$route.query.item.id;
    this.task_info = this.$route.query.item.task_info;
    this.task_name = this.$route.query.item.task_name;

    console.log(this.$route.query.item);
  },
  created() {
    this.gethomePage();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    gethomePage() {
      let data = { userId: window.localStorage.getItem("userId") };
      homePage(data).then((res) => {
        this.days = res.days;
        this.stage = res.stage;
        this.getgetEmoExercise();
      });
    },
    getgetEmoExercise() {
      let data = {
        userId: window.localStorage.getItem("userId"),
        id: this.id,
        days: this.days,
      };
      getEmoExercise(data).then((res) => {
        this.comment = decodeURIComponent(res.data.comment);
        this.img_url = res.data.img_url;
        console.log(this.img_url);
      });
    },
  },
};
</script>

<style lang="less">
.navbar {
  background-color: #ededed;
}
/deep/ .van-nav-bar .van-icon {
  color: #464646;
}
.ninjia {
  background-color: #f4fafe;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  .ninjia-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    img {
      width: 39px;
      height: 56px;
    }
  }
  .contentctx {
    background: #f6f9fd;
    border-radius: 8px;
    padding: 20px;
    box-sizing: border-box;
    margin: 20px 0;
  }
  .ninjia-btn {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    button {
      background-color: #629ee9;
      color: #fff;
      width: 200px;
      height: 36px;
      line-height: 36px;
      border: 0;
      border-radius: 50px;
    }
  }

  .jinritiyan {
    // height: 420px;
    background: #ffffff;
    box-shadow: 0px 2px 8px 0px rgba(185, 184, 184, 0.5);
    border-radius: 8px;
    padding: 20px;
    box-sizing: border-box;
  }
  .tiyanbianji {
    width: 100%;
    height: 168px;
    border-radius: 8px;
    border: 2px solid #becfe3;
    padding: 15px 25px;
    box-sizing: border-box;
    margin-bottom: 10px;
  }
  .shurukuang {
    width: 15px;
    height: 15px;
    position: relative;
    top: 38px;
    left: 10px;
  }
  .tianjia {
    width: 88px;
    height: 88px;
    border-radius: 8px;
    border: 1px solid #becfe3;
    line-height: 88px;
    text-align: center;
    color: #becfe3;
    font-size: 50px;
  }
  .ctx-title {
    .title-ctx {
      z-index: 999;
      position: relative;
      font-size: 18px;
      font-weight: 500;
      color: #000025;
      line-height: 25px;
    }
    .title-fk {
      width: 35px;
      height: 5px;
      background: #629ee9;
      position: relative;
      bottom: 8px;
      z-index: 998;
      transform: skew(-25deg);
    }
  }
  .imglist {
    display: flex;
    gap: 10px;
    align-items: center;
    img {
      width: 80px;
      height: 80px;
    }
  }
}
</style>
